
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

//$bs-font-sans-serif:      "Roboto", sans-serif;
$font-family-sans-serif:      "Roboto", sans-serif;
$headings-font-family:  "Roboto", sans-serif;
$headings-font-weight:        400;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #F8F5F0 !default;
$gray-300: #DFD7CA !default;
$gray-400: #ced4da !default;
$gray-500: #98978B !default;
$gray-600: #8E8C84 !default;
$gray-650: #525252 !default;
$gray-700: #495057 !default;
$gray-800: #3E3F3A !default;
$gray-900: #212921 !default;
$black:    #000 !default;

$blue:    #325D88 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #d9534f !default;
$orange:  #F47C3C !default;
$yellow:  #ffc107 !default;
$green: #6ca41a !default;
$teal:    #20c997 !default;
$cyan:    #29ABE0 !default;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-700 !default;


// scss-docs-start theme-color-variables
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;