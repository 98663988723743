


.page-404 {
  a {
    font-size: 1.3rem;
    color: white;
    &:visited {
      color:white;
    }
  }
}


.page-404 {
  min-height: 100%;
  //background-image: url("/assets/desert_sand.jpg");
  background-image: url("/assets/404.png");
  background-position: center bottom;
  background-size:100%;

  background-repeat: no-repeat;

  background-color: #009cc4;

  @include media-breakpoint-up(md) {
      background-position: center 50%;
  }

}

.page-404 h1 {
  font-size: 7em;
  font-weight: bold;
}

.page-404 p {
  font-size: 2em;
}




.page-error {
  a {
    font-size: 1.3rem;
    color: white;
    &:visited {
      color:white;
    }
  }
}


.page-error {
  min-height: 100%;
  //background-image: url("/assets/desert_sand.jpg");
  background-image: url("/assets/500.png");
  background-position: center bottom;
  background-size:100%;

  background-repeat: no-repeat;

  background-color: #009cc4;

  @include media-breakpoint-up(md) {
    background-position: center 50%;
  }

}

.page-error h1 {
  font-size: 7em;
  font-weight: bold;
}

.page-error p {
  font-size: 2em;
}




.page-sleep {
  min-height: 100%;
  //background-image: url("/assets/desert_sand.jpg");
  background-image: url("/assets/sleep.png");
  background-position: center bottom;
  background-size:100%;

  background-repeat: no-repeat;

  background-color: #1f3152;

  @include media-breakpoint-up(sm) {
    background-position: center 50%;
  }

}

.page-sleep h1 {
  font-size: 7em;
  font-weight: bold;
}

.page-sleep p {
  font-size: 2em;
}


.page-sleep .sleep-desktop {
  padding-top: 20rem !important;
}
