@import "variables";
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: 'LocalNative';
  src:  url('./fonts/local-native/icomoon.eot?km1m74');
  src:  url('./fonts/local-native/icomoon.eot?km1m74#iefix') format('embedded-opentype'),
  url('./fonts/local-native/icomoon.ttf?km1m74') format('truetype'),
  url('./fonts/local-native/icomoon.woff?km1m74') format('woff'),
  url('./fonts/local-native/icomoon.svg?km1m74#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-local-native:before {
  font-family: 'LocalNative';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.15;
  font-size: 1.2em;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  content: "\e900";
}


@import 'utils';
@import 'veeteed';
@import 'days';
@import 'progress_animation';
@import 'errors';

