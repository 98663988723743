//@import "../../node_modules/bootstrap/scss/functions";
//@import "../../node_modules/bootstrap/scss/helpers";
//@import "../../node_modules/bootstrap/scss/functions";
//@import "../../node_modules/bootstrap/scss/variables";
//@import "../../node_modules/bootstrap/scss/mixins";

.icon-column {
  width: 30%;
}

@include media-breakpoint-up(sm) {
  .icon-column {
    width: 20%;
  }
}
@include media-breakpoint-up(md) {
  .icon-column {
    width: 24%;
  }
}

@include media-breakpoint-up(lg) {
  .icon-column {
    width: 16%;
  }
}


@include media-breakpoint-up(xl) {
  .icon-column {
    width: 12%;
  }
}