.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.h-8px {
  height: 8px !important;
}

main {
  min-height: 500px;
}

.text-shadow {
  text-shadow: 1px 1px 1px rgba(52, 52, 52, 0.83);
}

.veeteed-header .text-muted {
  color: #c3d6e7 !important;
}

.veeteed-header a {
  color: #29abe0;
  text-decoration: none;
}

.animated-hidden {
  opacity: 0;
}

.animated-visible {
  opacity: 1 !important;
  transition: opacity 0.2s ease-in;
}

.header-image {
  background-image: url('/assets/sadamas3.png');
  background-position: center 70%;
  /*background-size: cover;*/
  background-repeat: repeat-x;
  background-color: #154254;
  background-size: cover;
}

@include media-breakpoint-up(sm) {
  .header-image {
    background-position: center 70%;
    background-size: initial;

  }
}

.footer-background {
  background-image: url('/assets/sadamas3.png');
  background-color: #154254;
  background-position: center 100%;
  background-repeat: repeat-x;
}

.footer-col-border {
  border-left: 1px solid #fff;
}

footer a {
  color: #fff;
}
footer a:hover {
  color: #eee;
}

.CookieConsent {
  a {
    color: #fff;
    &:hover {
      color: #eee;
    }
  }
}

.body-background {
  background-image: url("/assets/desert_sand.jpg");
  //background-image: url("/assets/waterbg.png");
  background-size: 200px;
  background-color: #d8b476;
}


@include media-breakpoint-up(md) {
  .body-background {
    background-size:200px;
  }
}

.progress-sm {
  height: 8px !important;
}

.progress-local {
  opacity: 0.7;
}
.progress-local.bg-danger{
  opacity: 1;
}

.h-route {
  font-size: 1.2em;
}

.fs-7 {
  font-size: 0.9rem;
}


@mixin letterpress() {
  text-shadow: 0px 1px 1px rgba(255,255,2555,0.75), 0px -1px 1px rgba(0,0,0,.75);
}



.text-letterpress {
  @include letterpress();
  //font-weight: bold;
 // font-size: 1.6em;
}


.text-time {
  color: $gray-650;
}

.fixed-top-reset {
  position: relative;
  top: 0;
}


.fixed-top-slide {
  position: fixed;
  top: 50px;
  transition: top 1s, position 1s;
  right: 0;
  left: 0;
  z-index: 1030;
}

.month-navbar-mobile {

  position: fixed;
  right: -100px;
  top: 300px;
  //bottom: 300px;
  z-index: 1030;
  transition: right 1s;

  .btn {
    border-radius: 5px 0 0 5px;
    transition: font-size 0.5s;

  }
  .btn:hover {
    font-size: 1.2em;

  }
}

.month-navbar-mobile.scroll {
  right: 0;


}


.fixed-bottom-slide {
  position: fixed;
  bottom: 50px;
  transition: bottom 1s;
  right: 0;
  left: 0;
  z-index: 1030;
}

.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}

.side-date span {
  display: none;
}

.day-card .departure:first-child .side-date span  {
  //border: 1px solid #0f0;
  display: inline;
}

.event-card  {
  background-color: #afafaf;
  color: #ffffff;
  min-height: 250px;
  //border: 1px solid #0f0;

  // background-image: url('http://localhost:3000/events/liisejooks7.jpg');
  background-position: center 100%;
  background-repeat: repeat-x;
  background-blend-mode: multiply;
  //filter: brightness(50%);

  //border: 2px solid #fff !important;
}

.event-card .event-title {
  font-size: 3em;
  font-weight: bold;
}

.event-card .event-link {
  font-size: .9em;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    color: #dcdcdc;
  }
  &:visited {
    color: #ffffff;
  }

}
