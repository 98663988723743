.ocean {
  /*border: 1px solid #f00;*/
  min-height: 100px;
  overflow: hidden;
  position: relative;
}
.wave {
  /*background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/756881/wavePurple.svg) repeat-x;*/
  /*background: url(wave.svg) repeat-x;*/
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 800 198' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath  d='m800 141.3c-155.5 0-204.95-89.316-405.5-89.095-200 0-250 89.095-394.5 89.095v56.697h800v-56.697z' fill='%23eeeeff' stroke-width='.60676'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-size: 100px;
  position: absolute;
  bottom: 12px;
  width: 6400px;
  height: 20px;

  animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, swell 1s ease -1.25s infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.5;
}

.wave-reverse {
  @extend .wave;

  animation: wavereverse 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, swell 1s ease -1.25s infinite;
}


.ship {
  /*background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/756881/wavePurple.svg) repeat-x;*/
  position: absolute;
  /*bottom: 15px;*/
  bottom:15px;
  left:40%;
  /*width: 6400px;*/
  /*height: 30px;*/
  animation: swell 1s ease -1.25s infinite;
  /*transform: translate3d(0, 0, 0);*/
  /*opacity: 0.5;*/

}

.ship img {
  height: 44px;
  // font-size: 1em;
}



.progress-flip {
  .ship img {
    transform: scaleX(-1);
  }
}


.wave2 {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 800 198' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath  d='m800 141.3c-155.5 0-204.95-89.316-405.5-89.095-200 0-250 89.095-394.5 89.095v56.697h800v-56.697z' fill='%23eeeeff' stroke-width='.60676'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-size: 100px;
  position: absolute;
  bottom: 12px;
  width: 6400px;
  height: 20px;

  /*background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/756881/wavePurple.svg) repeat-x;*/
  animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, swell 1s ease -1s infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}
.wave2-reverse {
  @extend .wave2;

  animation: wavereverse 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, swell 1s ease -1s infinite;
}



.cloud {
  background: url(../layout/cloud.svg) repeat-x;
  background-size: 300px 30px;
  position: absolute;
  bottom: 60px;
  width: 6400px;
  height: 30px;
  animation: wave 120s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite/*, swell 1s ease -1.25s infinite*/;
  transform: translate3d(0, 0, 0);
  opacity: 0.7;
}

.cloud-reverse {
  @extend .cloud;

  animation: wavereverse 120s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes wavereverse {
  100% {
    margin-left: 0;
  }
  0% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, 12px, 0);
  }
  50% {
    transform: translate3d(0, 10px, 0);
  }
}


@include media-breakpoint-up(sm) {
  .ship img {
    height: 88px;
    // font-size: 1em;
  }
  .wave {
    height: 30px;
    bottom: 5px;
  }
}

.progress-middle {
  .ship {
    left:40%;
  }
}

.progress-end {
  .ship {
    left:20%;
  }

}


/// ------------------------------------------------------------------------------
// LOADER




.ship-loader {
  position: relative;
  width: 200px;
  height: 100px;
  margin: 0 auto;
}

.ship-loader img {
  width: 100px;
  position: absolute;
  bottom: 15px;
  left: 50px;
  animation: ship-rotation .5s infinite alternate;
}

.loader-wave {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 800 198' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath  d='m800 141.3c-155.5 0-204.95-89.316-405.5-89.095-200 0-250 89.095-394.5 89.095v56.697h800v-56.697z' fill='%236FAAE8FF' stroke-width='.60676'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-size: 100px;
  position: absolute;
  bottom: 0;
  width: 200px;
  height: 30px;
  color: #6faae8;

  animation: scrolling-wave .5s linear infinite;
  opacity: 0.7;
}

@keyframes scrolling-wave {
  from {
    background-position: 100px 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes ship-rotation {
  from {
    transform: rotate(-4deg);
  }
  to {
    transform: rotate(4deg);
  }
}
